import { render, staticRenderFns } from "./elButton.vue?vue&type=template&id=ffa5ecd2&scoped=true&"
import script from "./elButton.vue?vue&type=script&lang=js&"
export * from "./elButton.vue?vue&type=script&lang=js&"
import style0 from "./elButton.vue?vue&type=style&index=0&id=ffa5ecd2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffa5ecd2",
  null
  
)

export default component.exports