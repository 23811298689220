<template>
	<el-input style="width: 100%;" @change="inputChange" :placeholder="matter.placeholder||placeholder" :type="matter.type||'string'"
		:validate="matter.validate||validate" :autofocus="matter.autofocus||autofocus"
		:readonly="matter.readonly||readonly" :disabled="disabled||matter.disabled"
		:show-password="showPass||matter.showPass" :minlength="matter.minlength||minlength"
		:min="matter.minlength||minlength" :max="maxlength||matter.maxlength" :maxlength="maxlength||matter.maxlength" v-model="value">
	</el-input>
</template>
<script>
	export default {
		props: {
			matter: {
				type: [Object],
				default: {
					type: 'string',
					// 最大输入长度
					maxlength: 100,
					// 最小输入长度
					minlength: 1,
					// 是否显示切换密码图标
					showPass: false,
					// clearable
					clearable: false,
					// 禁用
					disabled: false,
					// 是否只读
					readonly: false,
					// 自动获取焦点
					autofocus: false,
					// 输入时是否触发表单的校验
					validate: true,

				}
			},
			maxlength: {
				type: [Number],
				default: 1000
			},
			disabled: {
				type: [Boolean],
				default: false
			},
			showPass: {
				type: [Boolean],
				default: false
			},
			name: [String],
			iValue: [String, Number]
		},
		data() {
			return {
				value: '',
				// 最小输入长度
				minlength: 1,
				placeholder: '未添加输入文本',
				// 是否显示切换密码图标
				// clearable
				clearable: false,
				// 是否只读
				readonly: false,
				// 自动获取焦点
				autofocus: false,
				// 输入时是否触发表单的校验
				validate: true,
			}
		},
		watch: {
			value(val) {
				this.$emit('update:fatherValue', this.value);
			},
			iValue(val) {
				this.value = val;
			},
		},
		methods:{
			inputChange(){
				this.$emit('inputChange')
			}
		}
	}
</script>

<style lang="less" scoped>
	/deep/ .el-input__inner:focus {
		transition: all 0.4s linear 0s;
		border-color: #932224 !important;
	}

	/deep/ .el-input__inner {
		font-size: 20px;
		height: 58px !important;
		border-radius: 0;
	}
</style>
