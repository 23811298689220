<template>
	<div class="avatar-uploader_box">
		<el-upload class="avatar-uploader" :on-change="uploadChange" :on-progress="uploadVideoProcess" :on-success="handleSuccess"
			:before-upload="beforeUploadVideo" :show-file-list="false"
			action="https://v2admin.rmwc.com.cn/api/common/upload?lang=zh-cn" :auto-upload="true">
			<i v-if="videoForm.showVideoPath =='' && !videoFlag" class="el-icon-plus avatar-uploader-icon"></i>
			<el-progress v-if="videoFlag == true" type="circle" v-bind:percentage="videoUploadPercent"
				style="margin-top:0.45573vw;"></el-progress>
		</el-upload>
	</div>
</template>

<script>
	import axios from '../../api/axios.js';
	export default {
		props: {
			name: {
				type: [String],
				default: 'IMG'
			}
		},
		data() {
			return {
				// loading: true,
				videoFlag: false,
				//是否显示进度条
				videoUploadPercent: "",
				//进度条的进度，
				isShowUploadVideo: false,
				//显示上传按钮
				videoForm: {
					showVideoPath: ''
				},
				error: '',
				fileName: ''
			}
		},
		methods: {
			//上传前回调

			detection(file) {
				return new Promise((res, rej) => {
					let _this = this;
					let reader = new FileReader();
					let isreturn = true;
					reader.readAsDataURL(file);
					reader.onload = function() { //让页面中的img标签的src指向读取的路径 
						let img = new Image()
						img.src = reader.result
						if (img.complete) { //如果存在浏览器缓存中
							if (img.width != 4200 || img.height != 2970) {
								_this.$message.error({
									message: _this.$t('newText.msg118'),
									offset: 120
								})
								rej(true);
							} else {
								res(false);
							}

						} else {
							img.onload = function() {
								if (img.width != 4200 || img.height != 2970) {
									_this.$message.error({
										message: _this.$t('newText.msg118'),
										offset: 120
									})
									rej(true);
								} else {
									res(false);
								}
							}
						}
					}
				})
			},
			uploadformat(file) {
				return new Promise((res, rej) => {
					let show = true;
					this.fileName = file.name;
					var fileName = file.name.lastIndexOf("."); //取到文件名开始到最后一个点的长度
					var fileNameLength = file.name.length; //取到文件名长度
					var fileFormat = file.name.substring(fileName + 1, fileNameLength); //截
					var fileSize = file.size / 1024 / 1024 < 100;
					switch (this.name) {
						case 'PDF':
							fileSize = file.size / 1024 / 1024 < 10;
							if (['application/PDF', 'application/pdf'].indexOf(
									file.type) == -1) {
								this.$message.error({
									message: this.$t('newText.msg119'),
									offset: 120
								})
								return show = false;
							}
							this.error = this.$t('newText.msg120');
							break;
						case 'JPEG':
							fileSize = file.size / 1024 / 1024 < 1;

							if (['image/jpeg', 'image/JPEG'].indexOf(
									file.type) == -1) {
								this.$message.error({
									message: this.$t('newText.msg121'),
									offset: 120
								})
								return show = false;
							}
							this.error = this.$t('newText.msg122');
							break;
						case 'image':
							fileSize = file.size / 1024 / 1024 < 5;
							if (['image/png', 'image/PNG'].indexOf(
									file.type) == -1) {
								this.$message.error({
									message: this.$t('newText.msg123'),
									offset: 120
								})
								return show = false;
							}
							this.error = this.$t('newText.msg124');
							break;
						case 'IMG':
							fileSize = file.size / 1024 / 1024 < 5;
							if (['image/png', 'image/PNG'].indexOf(
									file.type) == -1) {
								this.$message.error({
									message: this.$t('newText.msg123'),
									offset: 120
								})
								return show = false;
							}
							this.error = this.$t('newText.msg125');
							break;
						case 'video':
							fileSize = file.size / 1024 / 1024 < 200;
							if (['video/mp4'].indexOf(
									file.type) == -1) {
								this.$message.error({
									message: this.$t('newText.msg126'),
									offset: 120
								})
								return show = false;
							}
							this.error = this.$t('newText.msg127');
							this.isShowUploadVideo = false;
							break;
						case 'PPT':
							fileSize = file.size / 1024 / 1024 < 100;
							if (['pdf'].indexOf(fileFormat) == -1) {
								this.$message.error({
									message: this.$t('newText.msg128'),
									offset: 120
								})
								return show = false;
							}
							this.error = this.$t('newText.msg129');
							break;
							case 'TX':
								if (['png','jpg','PNG','JPG','SVG','svg','psd','PSD'].indexOf(fileFormat) == -1) {
									this.$message.error({
										message: this.$t('newText.msg130'),
										offset: 120
									})
									return show = false;
								}
								break;
						default:
							fileSize = file.size / 1024 / 1024 < 50000;
							if (['fbx', 'dae', 'obj', 'stl'].indexOf(fileFormat) == -1) {
								this.$message.error({
									message: this.$t('newText.msg131'),
									offset: 120
								})
								return show = false;
							}
							this.error = this.$t('newText.msg132');
							break;
					}
					if (!fileSize) {
						this.$message.error({
							message: this.error,
							offset: 120
						})
						return show = false;
					}
					if (show) {
						res(show);
					}
				})
			},
			uploadChange(){
				this.$emit('upload',false);
			},
			async beforeUploadVideo(file) {
				if (this.name == 'IMG') {
					console.log(this.name)
					let res = await this.detection(file);
					if (res) {
						return
					}
				}
				let res = await this.uploadformat(file);
				if (res) {
					return
				}

			},
			//进度条
			uploadVideoProcess(event, file, fileList) {
				this.videoFlag = true;
				this.$emit('upload',true);
				this.videoUploadPercent = file.percentage.toFixed(0) * 1;
			},
			uploadUrl() {
				return ""
			},
			//上传成功回调
			handleSuccess(res, file) {
				if (res.data) {
					this.$emit('success', res.data.fullurl, this.fileName);
				} else {
					this.$message.error({
						message: res.msg,
						offset: 120
					})
				}
			}
		}
	}
</script>

<style lang="less">
	.avatar-uploader_box {
		background-color: red;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		opacity: 0;

		.avatar-uploader {
			width: 100%;
			height: 100%;
			position: relative;
		}

		i {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			top: 0;
		}
	}

	/deep/ .avatar-uploader-icon {
		width: 100px;
		height: 100px;
		background-color: #000;
		z-index: 99;
	}
</style>
