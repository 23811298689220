<template>
	<div class="elbutton">
		<ul class="clearfix_01" @click.stop="tapClick">
			<li>
				<span class="btn btn12 flex_disp text_2_size">
					<p class="flex_disp text_2_size" style="padding: 0 0.65104vw;">{{text||'保存'}}</p>
					<div class="bgsqr1"></div>
					<div class="bgsqr2"></div>
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		props: {
			text: [String],
			icon: [String]
		},
		methods: {
			tapClick() {
				this.$emit('btnTap');
			}
		}
	}
</script>

<style lang="less" scoped>
	.elbutton {
		width: 100%;
		height: 100%;
		cursor: pointer;
	}

	ul,
	li {
		list-style: none;
		padding: 0;
		margin: 0;
		width: 100%;
		height: 100%;
		justify-content: center;
		align-items: center;
	}

	// .clearfix{ zoom:1;}
	.btn {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		text-decoration: none;
		position: relative;
		color: #333;
	}

	.btn12 {
		align-items: center;
		justify-content: center;
		background-color: #932224;
		width: 100%;
		height: 100%;
		border: 1px solid #d0d0d0;
		position: relative;
		color: #666;
		overflow: hidden;
		-webkit-transition: all .6s;
		transition: all .6s;
		.bgsqr2 {
			right: -20px;
			-webkit-transform: translate(50%, -50%);
			transform: translate(50%, -50%);
		}
		p{
			align-items: center;
			justify-content: center;
			color: #fff;
			position: relative;
			z-index: 1;
			line-height: 100%;
			height: 100%;
		}
	}

	.btn12:hover {
		color: #fff !important;
		border-color: #932224;
		background-color: #fff;
	}

	.btn12 .bgsqr1,
	.btn12 .bgsqr2 {
		background: #932224;
		position: absolute;
		top: 50%;
		top: 0\9;
		content: '';
		width: 20px;
		height: 20px;
		-webkit-border-radius: 50%;
		border-radius: 50%;
		-webkit-transition: all 0.6s;
		transition: all 0.6s;
		opacity: 0.5;
	}

	.btn12 .bgsqr1 {
		left: -20px;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.btn12:hover{
		.bgsqr1 {
			-webkit-animation: criss-cross-left .8s both;
			animation: criss-cross-left .8s both;
			opacity: 1;
		}
		.bgsqr2 {
			-webkit-animation: criss-cross-right .8s both;
			animation: criss-cross-right .8s both;
			opacity: 1;
		}
	}
	@-webkit-keyframes criss-cross-left {
		0% {
			left: -20px
		}
		50% {
			left: 50%;
			width: 20px;
			height: 20px;
		}
		100% {
			left: 50%;
			width: 375px;
			height: 375px;
		}
	}

	@keyframes criss-cross-left {
		0% {
			left: -20px
		}

		50% {
			left: 50%;
			width: 20px;
			height: 20px;
		}

		100% {
			left: 50%;
			width: 375px;
			height: 375px;
		}
	}

	@-webkit-keyframes criss-cross-right {
		0% {
			right: -20px
		}

		50% {
			right: 50%;
			width: 20px;
			height: 20px;
		}

		100% {
			right: 50%;
			width: 100%;
			height: 100%;
		}
	}

	@keyframes criss-cross-right {
		0% {
			right: -20px
		}

		50% {
			right: 50%;
			width: 20px;
			height: 20px;
		}

		100% {
			right: 50%;
			width: 100%;
			height: 100%;
		}
	}
</style>
